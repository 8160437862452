
.live-end {
    flex: 1;
    display: flex;
    .container {
        .hint {
            font-size: 18px;
        }
        .time {
            margin: 20px 0 50px;
            font-size: 36px;
            font-weight: 700;
        }
        .avatar {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            overflow: hidden;
            .avatar-img {
                width: 100%;
                height: 100%;
            }
        }
        .username {
            margin: 24px 0 27px;
            font-size: 16px;
        }
        .button-list {
            height: 40px;
            .view-replay,
            .view-other-live {
                height: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #fe3266;
            }
            .view-other-live {
                background-color: #4b45ff;
            }
        }
    }
}
