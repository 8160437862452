
.live-other-info {
    box-sizing: border-box;
    //flex: 4;
    width: 35%;
    .container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .popularity {
            /* flex: 4.65; */
            margin-bottom: 20px;
            background-color: #0c0e3f;
        }
        .bullet-chat {
            flex: 1;
            background-color: #0c0e3f;
        }
    }
}
