
.lucky-bag {
    width: 100%;
    .container {
        .content {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .lucky-bag-item {
                box-sizing: border-box;
                padding: 20px 30px;
                border-bottom: 1px solid #ddd;
                .lucky-bag-info {
                    display: flex;
                    align-items: center;

                    .left-img-box {
                        width: 90px;
                        height: 95px;
                        .lucky-img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .right-info {
                        flex: 1;
                        margin: 0 46px 20px;
                        font-size: 16px;
                        color: #5c5c6f;
                        overflow: hidden;
                        .info-item {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .title {
                            margin-bottom: 20px;
                            font-size: 18px;
                            color: #1b162a;
                        }
                    }
                }
                .actions-button {
                    text-align: center;
                    .item {
                        box-sizing: border-box;
                        width: 130px;
                        height: 47px;
                        font-size: 16px;
                        letter-spacing: 2px;
                        border-radius: 6px;
                    }
                    .not-send {
                        color: #fff;
                        background-color: #ed2612;
                        border: 2px solid #e8b579;
                    }
                    .already-send {
                        color: #cc855c;
                        background-color: #fbdebc;
                        cursor: default;
                    }
                }
            }
        }
    }
}
